import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SignUp } from './pages/singup';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-K5T58N6',
};

TagManager.initialize(tagManagerArgs);

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<SignUp />}>
                    {' '}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
