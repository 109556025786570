/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button, Form, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { BsFillCheckSquareFill, BsFillXSquareFill } from 'react-icons/bs';
import { ethers } from 'ethers';

export const SignUp = () => {
    const [haveMetamask, sethaveMetamask] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [accountAddress, setAccountAddress] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();

    const ref = query.get('ref');
    const [user, setUser] = useState({
        nickname: '',
        email: '',
        wallet_address: '',
        password: '',
        password_confirmation: '',
        token: '',
        referral_code: ref,
    });
    const [response, setResponse] = useState({
        formSave: false,
        type: '',
        mensagem: '',
    });
    const [status, setStatus] = useState({
        type: 'sucess',
        mensagem: 'Lets go!',
        loading: false,
    });

    let provider = null;

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('chainChanged', () => {
                window.location.reload();
            });
            window.ethereum.on('accountsChanged', () => {
                window.location.reload();
            });
        }
    });

    useEffect(() => {
        provider = new ethers.providers.Web3Provider(window.ethereum);

        const { ethereum } = window;
        sethaveMetamask(!ethereum ? false : true);

        const connectMM = async () => {
            if (ethers) {
                try {
                    const accounts = await provider.send(
                        'eth_requestAccounts',
                        []
                    );

                    const networkId = (await provider.getNetwork()).chainId;

                    if (networkId !== 56) {
                        window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: '0x38',
                                    rpcUrls: [
                                        'https://rpc-mainnet.matic.network/',
                                    ],
                                    chainName: 'Smart Chain',
                                    nativeCurrency: {
                                        name: 'BNB',
                                        symbol: 'BNB',
                                        decimals: 18,
                                    },
                                    blockExplorerUrls: [
                                        'https://bsc-dataseed.binance.org/',
                                    ],
                                },
                            ],
                        });
                    }
                    setAccountAddress(accounts[0]);
                    setIsConnected(true);
                } catch (error) {
                    console.log(error);
                    setIsConnected(false);
                }
            }
        };

        connectMM();
    }, []);

    //   function influencer() {
    //       if (user.referral_code !== null || user.referral_code !== undefined) {
    //           document.querySelector('#input').disabled = true;
    //       } else {
    //           document.querySelector('#input').disabled = false;
    //       }
    //   }

    const onChangeInput = (e) =>
        setUser({ ...user, [e.target.name]: e.target.value });

    const sendContact = async (e) => {
        e.preventDefault();
        setResponse({ formSave: true });
        try {
            // como tornar essa url uma variável env
            const res = await fetch(
                'https://backend.pubgame.io/api/game/auth/players/sign-up',
                {
                    method: 'POST',
                    body: JSON.stringify(user),
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const responseEnv = await res.json();
            const emailuser = user.email;
            localStorage.setItem('email', emailuser);
            if (responseEnv.erro) {
                setResponse({
                    formSave: false,
                    type: 'error',
                    mensagem: 'Error, something is missing',
                });
            } else {
                setStatus({
                    type: 'success',
                    mensagem: 'You have been validated! Welcome to Pubgame!',
                });
                setUser({
                    nickname: '',
                    email: '',
                    wallet_address: '',
                    password: '',
                    password_confirmation: '',
                    token: '',
                    referral_code: '',
                });
            }
        } catch (err) {
            setResponse({
                formSave: false,
                type: 'error',
                mensagem: 'Error, try again',
            });
        }
    };

    return (
        <>
            <Container className="bodi">
                <Row>
                    <Col>
                        <img
                            src="/assets/images/barman.png"
                            alt="puber"
                            className="puber"
                        />
                    </Col>
                    <Col lg={6}>
                        <Row>
                            <Col>
                                <div className="main-heading register">
                                    <h2 className="loginz">Create Account</h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form
                                    className="form-box login"
                                    onSubmit={sendContact}
                                >
                                    <h3 className="loginz">Sign up</h3>
                                    <p>
                                        Your nickname is only used to other
                                        players identify you. <br />
                                        Your email address is used to login and
                                        send you important updates.
                                    </p>
                                    {!haveMetamask ? (
                                        <p>
                                            You need Metamask Installed to have
                                            access to the game. Install{' '}
                                            <a
                                                href="https://metamask.io/download/"
                                                target="_blank"
                                            >
                                                Metamask
                                            </a>
                                        </p>
                                    ) : (
                                        <></>
                                    )}

                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicName"
                                    >
                                        <Form.Label className="formlabel">
                                            Nickname
                                        </Form.Label>
                                        <Form.Control
                                            className="formcontrol"
                                            name="nickname"
                                            type="text"
                                            placeholder="HomerSimpson"
                                            onChange={onChangeInput}
                                            value={user.nickname || ''}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="formlabel">
                                            Email address
                                        </Form.Label>
                                        <Form.Control
                                            className="formcontrol"
                                            name="email"
                                            type="email"
                                            placeholder="homersimpson@gmail.com"
                                            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                            onChange={onChangeInput}
                                            value={user.email || ''}
                                            required
                                        />
                                        <Form.Text className="text-muted">
                                            We'll never share your email with
                                            anyone else.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicWallet"
                                    >
                                        <Form.Label className="formlabel">
                                            Wallet ID
                                        </Form.Label>
                                        <Form.Control
                                            className="formcontrol"
                                            name="wallet_address"
                                            type="text"
                                            /*placeholder="0x21cA294Ade7CD53d21b91dB885432724682c5f09"*/
                                            onChange={onChangeInput}
                                            value={
                                                isConnected
                                                    ? accountAddress
                                                    : ''
                                            }
                                            required
                                            readOnly={isConnected}
                                        />
                                        <Form.Text className="text-muted">
                                            We'll never share your ID with
                                            anyone else.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicPassword"
                                    >
                                        <Form.Label className="formlabel">
                                            Password
                                        </Form.Label>
                                        <Form.Control
                                            className="formcontrol"
                                            name="password"
                                            type="password"
                                            placeholder="**********"
                                            onChange={onChangeInput}
                                            value={user.password || ''}
                                            required
                                        />
                                        <Form.Text className="text-muted">
                                            Use eight or more characters with a
                                            combination of letters, numbers and
                                            symbols.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicPasswordConfirmation"
                                    >
                                        <Form.Label className="formlabel">
                                            Confirm password
                                        </Form.Label>
                                        <Form.Control
                                            className="formcontrol"
                                            name="password_confirmation"
                                            type="password"
                                            placeholder="**********"
                                            onChange={onChangeInput}
                                            value={
                                                user.password_confirmation || ''
                                            }
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicRef"
                                    >
                                        <Form.Label className="formlabel">
                                            Referral code
                                        </Form.Label>
                                        <Form.Control
                                            className="formcontrol"
                                            name="referral_code"
                                            type="text"
                                            placeholder="youtubername210"
                                            onChange={onChangeInput}
                                            value={user.referral_code || ''}
                                            readOnly={ref}
                                        />
                                        <Form.Text className="text-muted">
                                            Do you have any Referral Code from
                                            your favorite Youtuber? Insert it
                                            here!
                                        </Form.Text>
                                    </Form.Group>

                                    {!isConnected && haveMetamask ? (
                                        <>
                                            <p>
                                                You need to connect your
                                                Metamask to proceed with the
                                                SignUp.
                                            </p>
                                            <p>Reload the page to connect</p>
                                            <Button
                                                className="main-btn"
                                                onClick={() =>
                                                    window.location.reload()
                                                }
                                            >
                                                Reload Page
                                            </Button>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    <div className="button-area">
                                        {status.type === 'error' ? (
                                            <Modal
                                                show={show}
                                                onHide={handleClose}
                                            >
                                                <Modal.Body>
                                                    <BsFillXSquareFill className="btsicon" />
                                                    <p>{status.mensagem}</p>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button
                                                        variant="secondary"
                                                        onClick={handleClose}
                                                    >
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        ) : (
                                            ''
                                        )}
                                        {status.type === 'success' ? (
                                            <Modal
                                                show={show}
                                                onHide={handleClose}
                                            >
                                                <Modal.Body>
                                                    <BsFillCheckSquareFill className="btsicon" />
                                                    <p>{status.mensagem}</p>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button
                                                        variant="secondary"
                                                        onClick={handleClose}
                                                    >
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        ) : (
                                            ''
                                        )}
                                        {status.loading ? (
                                            <Button
                                                type="submit"
                                                className="main-btn"
                                                disabled
                                            >
                                                Creating...
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                className="main-btn"
                                                onClick={handleShow}
                                                disabled={!isConnected}
                                            >
                                                Create
                                            </Button>
                                        )}
                                        <br />
                                    </div>

                                    <div className="text-center">
                                        <p>
                                            Already have an account? <br />
                                            <a
                                                href="https://dashboard.pubgame.io/"
                                                className="link-pg-login"
                                            >
                                                Sign In
                                            </a>
                                        </p>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col>
                                <div className="main-heading register">
                                    <h2 className="loginz">Create Account</h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form className='form-box login' onSubmit={sendContact}>
                                    <h3 className="loginz">Important!</h3>
                                    <br /><br />
                                    <p>Hello everyone, we are no longer receiving new entries!</p>
                                    <br /><br />
                                    <p>But soon we will open new registrations.</p>                                
                                    <br /><br />
                                    <div className='text-center'>
                                        <p>Visit our website</p>
                                        <a href="https://pubgame.io/" className="link-pg-login"> pubgame.io</a> <br />

                                    </div>
                                    <br /><br />
                                </Form>
                            </Col>
                        </Row>
                        <br /><br />*/}
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </>
    );
};
